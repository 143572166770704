html {
    scroll-behavior: smooth;
}

body {
    min-height: 100vh;
    position: relative;
}

.link-anchor {
    scroll-margin: 85px 0 0 0;
}

.header {
    background: linear-gradient(346deg, rgba(2, 0, 36, 0.05) 0%, rgba(9, 9, 121, 0.06) 34%, rgba(159, 239, 255, 0.33) 100%);

}

.navbar.navbar-ontop {
    background: transparent !important;
    transition: all 0.400s in-out-ease;
}

.navbar:not(.navbar-ontop) {
    background-color: #fff;
    box-shadow: 0 .5rem 1rem rgba(0, 0, 0, .15);
}

.smaller {
    font-size: 60%;
}

.navbar {
    border-top: 5px solid $info;
}

.img-example {
    width: 100%;

    @include media-breakpoint-up(md) {
        width: 75%;
    }
}

.fa-spin {
    animation-duration: 6s;
}


.left-list {
    width: 500px;
margin-left: auto;
margin-right: auto;
text-align: left;
padding-left: 90px;
}

dfn {
    font-weight: bold;
    border-bottom: 1px dashed $info;
}

@media screen and (min-width:992px) {
    .bg-karel {
        background-image: url('img/karel.jpg');
        background-size: cover;
        background-position: right center;
    }
}
