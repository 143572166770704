// Customize Bootstrap Variables here
//
// You can USE and override any variable from
// /node_modules/bootstrap/scss/_variables.scss here
// and also use these variables to override others.
//
// You can do not only this:
// $gray-700: #575757;
//
// But also this:
// $gray-700: lighten($black, 71%);
//
// And also this:
// $gray-700: lighten($black, 71%);
// $link-color: $gray-700;
//
// Because of the nature of this solution,
// not only you need to add `!global` keyword
// after each overridden variable but also
// define it both in local and global context
// like this:
//
// $redefined-bs-variable: "value";
// $redefined-bs-variable: $redefined-bs-variable !global;
//
// If you want to use this method, don’t forget to change
// @import path to this file in /src/scss/index.scss
//
// Note: while ugly, this is the only workaround because
// of how variables and variable shadowing works in Sass.
//
// This files provides all Bootstrap’s variables
// ready to be overridden.

// RegExp to convert original _variables.scss by Bootstrap:
// `^\$([a-z0-9-]+):(\s+)(.*)(\s+)!default;` => `// \$$1: $3; \$$1: \$$1 !global; // $3`
// Complex structures as maps etc. must be edited manually.

@import url('https://fonts.googleapis.com/css2?family=Public+Sans:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');

@import "bootstrap-variables-init"; // init all variables so dart sass doesn’t throw deprecation warnings

%custom-bootstrap-variables {
  @import "node_modules/bootstrap/scss/functions";
  @import "node_modules/bootstrap/scss/variables";

  // Variables
  //
  // Variables should follow the `$component-state-property-size` formula for
  // consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

  // Color system

  $white: #fff;
  $white: $white !global; // #fff
  $gray-100: #f8f9fa;
  $gray-100: $gray-100 !global; // #f8f9fa
  $gray-200: #e9ecef;
  $gray-200: $gray-200 !global; // #e9ecef
  $gray-300: #dee2e6;
  $gray-300: $gray-300 !global; // #dee2e6
  $gray-400: #ced4da;
  $gray-400: $gray-400 !global; // #ced4da
  $gray-500: #adb5bd;
  $gray-500: $gray-500 !global; // #adb5bd
  $gray-600: #6c757d;
  $gray-600: $gray-600 !global; // #6c757d
  $gray-700: #495057;
  $gray-700: $gray-700 !global; // #495057
  $gray-800: #343a40;
  $gray-800: $gray-800 !global; // #343a40
  $gray-900: #212529;
  $gray-900: $gray-900 !global; // #212529
  $black: #000;
  $black: $black !global; // #000

  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    // stylelint-disable-next-line scss/dollar-variable-default
    $grays: (
    //        "100": $gray-100,
    //        "200": $gray-200,
    //        "300": $gray-300,
    //        "400": $gray-400,
    //        "500": $gray-500,
    //        "600": $gray-600,
    //        "700": $gray-700,
    //        "800": $gray-800,
    //        "900": $gray-900
    ); $grays: $grays !global;
    */
  // $blue: #007bff; $blue: $blue !global; // #007bff
  // $indigo: #6610f2; $indigo: $indigo !global; // #6610f2
  // $purple: #6f42c1; $purple: $purple !global; // #6f42c1
  // $pink: #e83e8c; $pink: $pink !global; // #e83e8c
  // $red: #dc3545; $red: $red !global; // #dc3545
  // $orange: #fd7e14; $orange: $orange !global; // #fd7e14
  // $yellow: #ffc107; $yellow: $yellow !global; // #ffc107
  // $green: #28a745; $green: $green !global; // #28a745
  // $teal: #20c997; $teal: $teal !global; // #20c997
  // $cyan: #17a2b8; $cyan: $cyan !global; // #17a2b8

  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    // stylelint-disable-next-line scss/dollar-variable-default
    $colors: (
    //        "blue": $blue,
    //        "indigo": $indigo,
    //        "purple": $purple,
    //        "pink": $pink,
    //        "red": $red,
    //        "orange": $orange,
    //        "yellow": $yellow,
    //        "green": $green,
    //        "teal": $teal,
    //        "cyan": $cyan,
    //        "white": $white,
    //        "gray": $gray-600,
    //        "gray-dark": $gray-800
    ); $colors: $colors !global;
    */

  // $primary: $blue; $primary: $primary !global; // $blue
  // $secondary: $gray-600; $secondary: $secondary !global; // $gray-600
  // $success: $green; $success: $success !global; // $green
  $info: #004896;
  $info: $info !global; // $cyan
  // $warning: $yellow; $warning: $warning !global; // $yellow
  // $danger: $red; $danger: $danger !global; // $red
  $light: #ebf3f9;
  $light: $light !global; // $gray-100
  // $dark: $gray-800; $dark: $dark !global; // $gray-800

  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    // stylelint-disable-next-line scss/dollar-variable-default
    $theme-colors: (
    //        "primary": $primary,
    //        "secondary": $secondary,
    //        "success": $success,
    //        "info": $info,
    //        "warning": $warning,
    //        "danger": $danger,
    //        "light": $light,
    //        "dark": $dark
    ); $theme-colors: $theme-colors !global;
    */

  // Set a specific jump point for requesting color jumps
  // $theme-color-interval: 8%; $theme-color-interval: $theme-color-interval !global; // 8%

  // The yiq lightness value that determines when the lightness of color changes from "dark" to "light". Acceptable values are between 0 and 255.
  // $yiq-contrasted-threshold: 150; $yiq-contrasted-threshold: $yiq-contrasted-threshold !global; // 150

  // Customize the light and dark text colors for use in our YIQ color contrast function.
  // $yiq-text-dark: $gray-900; $yiq-text-dark: $yiq-text-dark !global; // $gray-900
  // $yiq-text-light: $white; $yiq-text-light: $yiq-text-light !global; // $white

  // Characters which are escaped by the escape-svg function

  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    $escaped-characters: (
    //        ("<","%3c"),
    //        (">","%3e"),
    //        ("#","%23"),
    //        ("(","%28"),
    //        (")","%29"),
    ); $escaped-characters: $escaped-characters !global;
    */

  // Options
  //
  // Quickly modify global styling by enabling or disabling optional features.

  // $enable-caret: true; $enable-caret: $enable-caret !global; // true
  // $enable-rounded: true; $enable-rounded: $enable-rounded !global; // true
  // $enable-shadows: false; $enable-shadows: $enable-shadows !global; // false
  // $enable-gradients: false; $enable-gradients: $enable-gradients !global; // false
  // $enable-transitions: true; $enable-transitions: $enable-transitions !global; // true
  // $enable-prefers-reduced-motion-media-query: true; $enable-prefers-reduced-motion-media-query: $enable-prefers-reduced-motion-media-query !global; // true
  // $enable-hover-media-query: false; $enable-hover-media-query: $enable-hover-media-query !global; // false // Deprecated, no longer affects any compiled CSS
  // $enable-grid-classes: true; $enable-grid-classes: $enable-grid-classes !global; // true
  // $enable-pointer-cursor-for-buttons: true; $enable-pointer-cursor-for-buttons: $enable-pointer-cursor-for-buttons !global; // true
  // $enable-print-styles: true; $enable-print-styles: $enable-print-styles !global; // true
  $enable-responsive-font-sizes: true;
  $enable-responsive-font-sizes: $enable-responsive-font-sizes !global; // false
  // $enable-validation-icons: true; $enable-validation-icons: $enable-validation-icons !global; // true
  // $enable-deprecation-messages: true; $enable-deprecation-messages: $enable-deprecation-messages !global; // true


  // Spacing
  //
  // Control the default styling of most Bootstrap elements by modifying these
  // variables. Mostly focused on spacing.
  // You can add more entries to the $spacers map, should you need more variation.

  // $spacer: 1rem; $spacer: $spacer !global; // 1rem
  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    // stylelint-disable-next-line scss/dollar-variable-default
    $spacers: (
    //        0: 0,
    //        1: ($spacer * .25),
    //        2: ($spacer * .5),
    //        3: $spacer,
    //        4: ($spacer * 1.5),
    //        5: ($spacer * 3)
    ); $spacers: $spacers !global;
    */

  // This variable affects the `.h-*` and `.w-*` classes.
  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    // stylelint-disable-next-line scss/dollar-variable-default
    $sizes: (
    //        25: 25%,
    //        50: 50%,
    //        75: 75%,
    //        100: 100%,
    //        auto: auto
    ); $sizes: $sizes !global;
    */

  // Body
  //
  // Settings for the `<body>` element.

  // $body-bg: $white; $body-bg: $body-bg !global; // $white
  // $body-color: $gray-900; $body-color: $body-color !global; // $gray-900


  // Links
  //
  // Style anchor elements.

  // $link-color: theme-color("primary"); $link-color: $link-color !global; // theme-color("primary")
  // $link-decoration: none; $link-decoration: $link-decoration !global; // none
  // $link-hover-color: darken($link-color, 15%); $link-hover-color: $link-hover-color !global; // darken($link-color, 15%)
  // $link-hover-decoration: underline; $link-hover-decoration: $link-hover-decoration !global; // underline
  // Darken percentage for links with `.text-*` class (e.g. `.text-success`)
  // $emphasized-link-hover-darken-percentage: 15%; $emphasized-link-hover-darken-percentage: $emphasized-link-hover-darken-percentage !global; // 15%

  // Paragraphs
  //
  // Style p element.

  // $paragraph-margin-bottom: 1rem; $paragraph-margin-bottom: $paragraph-margin-bottom !global; // 1rem


  // Grid breakpoints
  //
  // Define the minimum dimensions at which your layout will change,
  // adapting to different screen sizes, for use in media queries.
  /*
    $grid-breakpoints: (
    //      xs: 0,
    //      sm: 576px,
    //      md: 768px,
    //      lg: 992px,
    //      xl: 1200px
    ); $grid-breakpoints: $grid-breakpoints !global;

    @include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
    @include _assert-starts-at-zero($grid-breakpoints, "$grid-breakpoints");
    */


  // Grid containers
  //
  // Define the maximum width of `.container` for different screen sizes.

  /*
    $container-max-widths: (
      sm: 540px,
      md: 720px,
      lg: 960px,
      xl: 1140px
    ); $container-max-widths: $container-max-widths !global;

    @include _assert-ascending($container-max-widths, "$container-max-widths");
    */


  // Grid columns
  //
  // Set the number of columns and specify the width of the gutters.

  // $grid-columns: 12; $grid-columns: $grid-columns !global; // 12
  // $grid-gutter-width: 30px; $grid-gutter-width: $grid-gutter-width !global; // 30px
  // $grid-row-columns: 6; $grid-row-columns: $grid-row-columns !global; // 6

  // Components
  //
  // Define common padding and border radius sizes and more.

  // $line-height-lg: 1.5; $line-height-lg: $line-height-lg !global; // 1.5
  // $line-height-sm: 1.5; $line-height-sm: $line-height-sm !global; // 1.5

  // $border-width: 1px; $border-width: $border-width !global; // 1px
  // $border-color: $gray-300; $border-color: $border-color !global; // $gray-300

  // $border-radius: .25rem; $border-radius: $border-radius !global; // .25rem
  // $border-radius-lg: .3rem; $border-radius-lg: $border-radius-lg !global; // .3rem
  // $border-radius-sm: .2rem; $border-radius-sm: $border-radius-sm !global; // .2rem

  // $rounded-pill: 50rem; $rounded-pill: $rounded-pill !global; // 50rem

  // $box-shadow-sm: 0 .125rem .25rem rgba($black, .075); $box-shadow-sm: $box-shadow-sm !global; // 0 .125rem .25rem rgba($black, .075)
  // $box-shadow: 0 .5rem 1rem rgba($black, .15); $box-shadow: $box-shadow !global; // 0 .5rem 1rem rgba($black, .15)
  // $box-shadow-lg: 0 1rem 3rem rgba($black, .175); $box-shadow-lg: $box-shadow-lg !global; // 0 1rem 3rem rgba($black, .175)

  // $component-active-color: $white; $component-active-color: $component-active-color !global; // $white
  // $component-active-bg: theme-color("primary"); $component-active-bg: $component-active-bg !global; // theme-color("primary")

  // $caret-width: .3em; $caret-width: $caret-width !global; // .3em
  // $caret-vertical-align: $caret-width * .85; $caret-vertical-align: $caret-vertical-align !global; // $caret-width * .85
  // $caret-spacing: $caret-width * .85; $caret-spacing: $caret-spacing !global; // $caret-width * .85

  // $transition-base: all .2s ease-in-out; $transition-base: $transition-base !global; // all .2s ease-in-out
  // $transition-fade: opacity .15s linear; $transition-fade: $transition-fade !global; // opacity .15s linear
  // $transition-collapse: height .35s ease; $transition-collapse: $transition-collapse !global; // height .35s ease

  // uncomment whole block, the following variable uses join(),
  // so you can define only new ones you need
  /*
    $embed-responsive-aspect-ratios: (
    //        (21 9),
    //        (16 9),
    //        (4 3),
    //        (1 1),
    ); $embed-responsive-aspect-ratios: $embed-responsive-aspect-ratios !global;
    */

  // Typography
  //
  // Font, line-height, and color for body text, headings, and more.

  // stylelint-disable value-keyword-case
  $font-family-sans-serif: "Public Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  $font-family-sans-serif: $font-family-sans-serif !global; // -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
  // $font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace; $font-family-monospace: $font-family-monospace !global; // SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace
  // $font-family-base: $font-family-sans-serif; $font-family-base: $font-family-base !global; // $font-family-sans-serif
  // stylelint-enable value-keyword-case

  // $font-size-base: 1rem; $font-size-base: $font-size-base !global; // 1rem // Assumes the browser default, typically `16px`
  // $font-size-lg: $font-size-base * 1.25; $font-size-lg: $font-size-lg !global; // $font-size-base * 1.25
  // $font-size-sm: $font-size-base * .875; $font-size-sm: $font-size-sm !global; // $font-size-base * .875

  // $font-weight-lighter: lighter; $font-weight-lighter: $font-weight-lighter !global; // lighter
  // $font-weight-light: 300; $font-weight-light: $font-weight-light !global; // 300
  // $font-weight-normal: 400; $font-weight-normal: $font-weight-normal !global; // 400
  // $font-weight-bold: 700; $font-weight-bold: $font-weight-bold !global; // 700
  // $font-weight-bolder: bolder; $font-weight-bolder: $font-weight-bolder !global; // bolder

  // $font-weight-base: $font-weight-normal; $font-weight-base: $font-weight-base !global; // $font-weight-normal
  // $line-height-base: 1.5; $line-height-base: $line-height-base !global; // 1.5

  // $h1-font-size: $font-size-base * 2.5; $h1-font-size: $h1-font-size !global; // $font-size-base * 2.5
  // $h2-font-size: $font-size-base * 2; $h2-font-size: $h2-font-size !global; // $font-size-base * 2
  // $h3-font-size: $font-size-base * 1.75; $h3-font-size: $h3-font-size !global; // $font-size-base * 1.75
  // $h4-font-size: $font-size-base * 1.5; $h4-font-size: $h4-font-size !global; // $font-size-base * 1.5
  // $h5-font-size: $font-size-base * 1.25; $h5-font-size: $h5-font-size !global; // $font-size-base * 1.25
  // $h6-font-size: $font-size-base; $h6-font-size: $h6-font-size !global; // $font-size-base

  // $headings-margin-bottom: $spacer / 2; $headings-margin-bottom: $headings-margin-bottom !global; // $spacer / 2
  // $headings-font-family: null; $headings-font-family: $headings-font-family !global; // null
  // $headings-font-weight: 500; $headings-font-weight: $headings-font-weight !global; // 500
  // $headings-line-height: 1.2; $headings-line-height: $headings-line-height !global; // 1.2
  // $headings-color: null; $headings-color: $headings-color !global; // null

  // $display1-size: 6rem; $display1-size: $display1-size !global; // 6rem
  // $display2-size: 5.5rem; $display2-size: $display2-size !global; // 5.5rem
  // $display3-size: 4.5rem; $display3-size: $display3-size !global; // 4.5rem
  // $display4-size: 3.5rem; $display4-size: $display4-size !global; // 3.5rem

  // $display1-weight: 300; $display1-weight: $display1-weight !global; // 300
  // $display2-weight: 300; $display2-weight: $display2-weight !global; // 300
  // $display3-weight: 300; $display3-weight: $display3-weight !global; // 300
  // $display4-weight: 300; $display4-weight: $display4-weight !global; // 300
  // $display-line-height: $headings-line-height; $display-line-height: $display-line-height !global; // $headings-line-height

  // $lead-font-size: $font-size-base * 1.25; $lead-font-size: $lead-font-size !global; // $font-size-base * 1.25
  // $lead-font-weight: 300; $lead-font-weight: $lead-font-weight !global; // 300

  // $small-font-size: 80%; $small-font-size: $small-font-size !global; // 80%

  // $text-muted: $gray-600; $text-muted: $text-muted !global; // $gray-600

  // $blockquote-small-color: $gray-600; $blockquote-small-color: $blockquote-small-color !global; // $gray-600
  // $blockquote-small-font-size: $small-font-size; $blockquote-small-font-size: $blockquote-small-font-size !global; // $small-font-size
  // $blockquote-font-size: $font-size-base * 1.25; $blockquote-font-size: $blockquote-font-size !global; // $font-size-base * 1.25

  // $hr-border-color: rgba($black, .1); $hr-border-color: $hr-border-color !global; // rgba($black, .1)
  // $hr-border-width: $border-width; $hr-border-width: $hr-border-width !global; // $border-width

  // $mark-padding: .2em; $mark-padding: $mark-padding !global; // .2em

  // $dt-font-weight: $font-weight-bold; $dt-font-weight: $dt-font-weight !global; // $font-weight-bold

  // $kbd-box-shadow: inset 0 -.1rem 0 rgba($black, .25); $kbd-box-shadow: $kbd-box-shadow !global; // inset 0 -.1rem 0 rgba($black, .25)
  // $nested-kbd-font-weight: $font-weight-bold; $nested-kbd-font-weight: $nested-kbd-font-weight !global; // $font-weight-bold

  // $list-inline-padding: .5rem; $list-inline-padding: $list-inline-padding !global; // .5rem

  // $mark-bg: #fcf8e3; $mark-bg: $mark-bg !global; // #fcf8e3

  // $hr-margin-y: $spacer; $hr-margin-y: $hr-margin-y !global; // $spacer


  // Tables
  //
  // Customizes the `.table` component with basic values, each used across all table variations.

  // $table-cell-padding: .75rem; $table-cell-padding: $table-cell-padding !global; // .75rem
  // $table-cell-padding-sm: .3rem; $table-cell-padding-sm: $table-cell-padding-sm !global; // .3rem

  // $table-color: $body-color; $table-color: $table-color !global; // $body-color
  // $table-bg: null; $table-bg: $table-bg !global; // null
  // $table-accent-bg: rgba($black, .05); $table-accent-bg: $table-accent-bg !global; // rgba($black, .05)
  // $table-hover-color: $table-color; $table-hover-color: $table-hover-color !global; // $table-color
  // $table-hover-bg: rgba($black, .075); $table-hover-bg: $table-hover-bg !global; // rgba($black, .075)
  // $table-active-bg: $table-hover-bg; $table-active-bg: $table-active-bg !global; // $table-hover-bg

  // $table-border-width: $border-width; $table-border-width: $table-border-width !global; // $border-width
  // $table-border-color: $border-color; $table-border-color: $table-border-color !global; // $border-color

  // $table-head-bg: $gray-200; $table-head-bg: $table-head-bg !global; // $gray-200
  // $table-head-color: $gray-700; $table-head-color: $table-head-color !global; // $gray-700

  // $table-dark-color: $white; $table-dark-color: $table-dark-color !global; // $white
  // $table-dark-bg: $gray-800; $table-dark-bg: $table-dark-bg !global; // $gray-800
  // $table-dark-accent-bg: rgba($white, .05); $table-dark-accent-bg: $table-dark-accent-bg !global; // rgba($white, .05)
  // $table-dark-hover-color: $table-dark-color; $table-dark-hover-color: $table-dark-hover-color !global; // $table-dark-color
  // $table-dark-hover-bg: rgba($white, .075); $table-dark-hover-bg: $table-dark-hover-bg !global; // rgba($white, .075)
  // $table-dark-border-color: lighten($table-dark-bg, 7.5%); $table-dark-border-color: $table-dark-border-color !global; // lighten($table-dark-bg, 7.5%)

  // $table-striped-order: odd; $table-striped-order: $table-striped-order !global; // odd

  // $table-caption-color: $text-muted; $table-caption-color: $table-caption-color !global; // $text-muted

  // $table-bg-level: -9; $table-bg-level: $table-bg-level !global; // -9
  // $table-border-level: -6; $table-border-level: $table-border-level !global; // -6


  // Buttons + Forms
  //
  // Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

  // $input-btn-padding-y: .375rem; $input-btn-padding-y: $input-btn-padding-y !global; // .375rem
  // $input-btn-padding-x: .75rem; $input-btn-padding-x: $input-btn-padding-x !global; // .75rem
  // $input-btn-font-family: null; $input-btn-font-family: $input-btn-font-family !global; // null
  // $input-btn-font-size: $font-size-base; $input-btn-font-size: $input-btn-font-size !global; // $font-size-base
  // $input-btn-line-height: $line-height-base; $input-btn-line-height: $input-btn-line-height !global; // $line-height-base

  // $input-btn-focus-width: .2rem; $input-btn-focus-width: $input-btn-focus-width !global; // .2rem
  // $input-btn-focus-color: rgba($component-active-bg, .25); $input-btn-focus-color: $input-btn-focus-color !global; // rgba($component-active-bg, .25)
  // $input-btn-focus-box-shadow: 0 0 0 $input-btn-focus-width $input-btn-focus-color; $input-btn-focus-box-shadow: $input-btn-focus-box-shadow !global; // 0 0 0 $input-btn-focus-width $input-btn-focus-color

  // $input-btn-padding-y-sm: .25rem; $input-btn-padding-y-sm: $input-btn-padding-y-sm !global; // .25rem
  // $input-btn-padding-x-sm: .5rem; $input-btn-padding-x-sm: $input-btn-padding-x-sm !global; // .5rem
  // $input-btn-font-size-sm: $font-size-sm; $input-btn-font-size-sm: $input-btn-font-size-sm !global; // $font-size-sm
  // $input-btn-line-height-sm: $line-height-sm; $input-btn-line-height-sm: $input-btn-line-height-sm !global; // $line-height-sm

  // $input-btn-padding-y-lg: .5rem; $input-btn-padding-y-lg: $input-btn-padding-y-lg !global; // .5rem
  // $input-btn-padding-x-lg: 1rem; $input-btn-padding-x-lg: $input-btn-padding-x-lg !global; // 1rem
  // $input-btn-font-size-lg: $font-size-lg; $input-btn-font-size-lg: $input-btn-font-size-lg !global; // $font-size-lg
  // $input-btn-line-height-lg: $line-height-lg; $input-btn-line-height-lg: $input-btn-line-height-lg !global; // $line-height-lg

  // $input-btn-border-width: $border-width; $input-btn-border-width: $input-btn-border-width !global; // $border-width


  // Buttons
  //
  // For each of Bootstrap's buttons, define text, background, and border color.

  // $btn-padding-y: $input-btn-padding-y; $btn-padding-y: $btn-padding-y !global; // $input-btn-padding-y
  // $btn-padding-x: $input-btn-padding-x; $btn-padding-x: $btn-padding-x !global; // $input-btn-padding-x
  // $btn-font-family: $input-btn-font-family; $btn-font-family: $btn-font-family !global; // $input-btn-font-family
  // $btn-font-size: $input-btn-font-size; $btn-font-size: $btn-font-size !global; // $input-btn-font-size
  // $btn-line-height: $input-btn-line-height; $btn-line-height: $btn-line-height !global; // $input-btn-line-height
  // $btn-white-space: null; $btn-white-space: $btn-white-space !global; // null // Set to `nowrap` to prevent text wrapping

  // $btn-padding-y-sm: $input-btn-padding-y-sm; $btn-padding-y-sm: $btn-padding-y-sm !global; // $input-btn-padding-y-sm
  // $btn-padding-x-sm: $input-btn-padding-x-sm; $btn-padding-x-sm: $btn-padding-x-sm !global; // $input-btn-padding-x-sm
  // $btn-font-size-sm: $input-btn-font-size-sm; $btn-font-size-sm: $btn-font-size-sm !global; // $input-btn-font-size-sm
  // $btn-line-height-sm: $input-btn-line-height-sm; $btn-line-height-sm: $btn-line-height-sm !global; // $input-btn-line-height-sm

  // $btn-padding-y-lg: $input-btn-padding-y-lg; $btn-padding-y-lg: $btn-padding-y-lg !global; // $input-btn-padding-y-lg
  // $btn-padding-x-lg: $input-btn-padding-x-lg; $btn-padding-x-lg: $btn-padding-x-lg !global; // $input-btn-padding-x-lg
  // $btn-font-size-lg: $input-btn-font-size-lg; $btn-font-size-lg: $btn-font-size-lg !global; // $input-btn-font-size-lg
  // $btn-line-height-lg: $input-btn-line-height-lg; $btn-line-height-lg: $btn-line-height-lg !global; // $input-btn-line-height-lg

  // $btn-border-width: $input-btn-border-width; $btn-border-width: $btn-border-width !global; // $input-btn-border-width

  // $btn-font-weight: $font-weight-normal; $btn-font-weight: $btn-font-weight !global; // $font-weight-normal
  // $btn-box-shadow: inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075); $btn-box-shadow: $btn-box-shadow !global; // inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075)
  // $btn-focus-width: $input-btn-focus-width; $btn-focus-width: $btn-focus-width !global; // $input-btn-focus-width
  // $btn-focus-box-shadow: $input-btn-focus-box-shadow; $btn-focus-box-shadow: $btn-focus-box-shadow !global; // $input-btn-focus-box-shadow
  // $btn-disabled-opacity: .65; $btn-disabled-opacity: $btn-disabled-opacity !global; // .65
  // $btn-active-box-shadow: inset 0 3px 5px rgba($black, .125); $btn-active-box-shadow: $btn-active-box-shadow !global; // inset 0 3px 5px rgba($black, .125)

  // $btn-link-disabled-color: $gray-600; $btn-link-disabled-color: $btn-link-disabled-color !global; // $gray-600

  // $btn-block-spacing-y: .5rem; $btn-block-spacing-y: $btn-block-spacing-y !global; // .5rem

  // Allows for customizing button radius independently from global border radius
  // $btn-border-radius: $border-radius; $btn-border-radius: $btn-border-radius !global; // $border-radius
  // $btn-border-radius-lg: $border-radius-lg; $btn-border-radius-lg: $btn-border-radius-lg !global; // $border-radius-lg
  // $btn-border-radius-sm: $border-radius-sm; $btn-border-radius-sm: $btn-border-radius-sm !global; // $border-radius-sm

  // $btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; $btn-transition: $btn-transition !global; // color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out


  // Forms

  // $label-margin-bottom: .5rem; $label-margin-bottom: $label-margin-bottom !global; // .5rem

  // $input-padding-y: $input-btn-padding-y; $input-padding-y: $input-padding-y !global; // $input-btn-padding-y
  // $input-padding-x: $input-btn-padding-x; $input-padding-x: $input-padding-x !global; // $input-btn-padding-x
  // $input-font-family: $input-btn-font-family; $input-font-family: $input-font-family !global; // $input-btn-font-family
  // $input-font-size: $input-btn-font-size; $input-font-size: $input-font-size !global; // $input-btn-font-size
  // $input-font-weight: $font-weight-base; $input-font-weight: $input-font-weight !global; // $font-weight-base
  // $input-line-height: $input-btn-line-height; $input-line-height: $input-line-height !global; // $input-btn-line-height

  // $input-padding-y-sm: $input-btn-padding-y-sm; $input-padding-y-sm: $input-padding-y-sm !global; // $input-btn-padding-y-sm
  // $input-padding-x-sm: $input-btn-padding-x-sm; $input-padding-x-sm: $input-padding-x-sm !global; // $input-btn-padding-x-sm
  // $input-font-size-sm: $input-btn-font-size-sm; $input-font-size-sm: $input-font-size-sm !global; // $input-btn-font-size-sm
  // $input-line-height-sm: $input-btn-line-height-sm; $input-line-height-sm: $input-line-height-sm !global; // $input-btn-line-height-sm

  // $input-padding-y-lg: $input-btn-padding-y-lg; $input-padding-y-lg: $input-padding-y-lg !global; // $input-btn-padding-y-lg
  // $input-padding-x-lg: $input-btn-padding-x-lg; $input-padding-x-lg: $input-padding-x-lg !global; // $input-btn-padding-x-lg
  // $input-font-size-lg: $input-btn-font-size-lg; $input-font-size-lg: $input-font-size-lg !global; // $input-btn-font-size-lg
  // $input-line-height-lg: $input-btn-line-height-lg; $input-line-height-lg: $input-line-height-lg !global; // $input-btn-line-height-lg

  // $input-bg: $white; $input-bg: $input-bg !global; // $white
  // $input-disabled-bg: $gray-200; $input-disabled-bg: $input-disabled-bg !global; // $gray-200

  // $input-color: $gray-700; $input-color: $input-color !global; // $gray-700
  // $input-border-color: $gray-400; $input-border-color: $input-border-color !global; // $gray-400
  // $input-border-width: $input-btn-border-width; $input-border-width: $input-border-width !global; // $input-btn-border-width
  // $input-box-shadow: inset 0 1px 1px rgba($black, .075); $input-box-shadow: $input-box-shadow !global; // inset 0 1px 1px rgba($black, .075)

  // $input-border-radius: $border-radius; $input-border-radius: $input-border-radius !global; // $border-radius
  // $input-border-radius-lg: $border-radius-lg; $input-border-radius-lg: $input-border-radius-lg !global; // $border-radius-lg
  // $input-border-radius-sm: $border-radius-sm; $input-border-radius-sm: $input-border-radius-sm !global; // $border-radius-sm

  // $input-focus-bg: $input-bg; $input-focus-bg: $input-focus-bg !global; // $input-bg
  // $input-focus-border-color: lighten($component-active-bg, 25%); $input-focus-border-color: $input-focus-border-color !global; // lighten($component-active-bg, 25%)
  // $input-focus-color: $input-color; $input-focus-color: $input-focus-color !global; // $input-color
  // $input-focus-width: $input-btn-focus-width; $input-focus-width: $input-focus-width !global; // $input-btn-focus-width
  // $input-focus-box-shadow: $input-btn-focus-box-shadow; $input-focus-box-shadow: $input-focus-box-shadow !global; // $input-btn-focus-box-shadow

  // $input-placeholder-color: $gray-600; $input-placeholder-color: $input-placeholder-color !global; // $gray-600
  // $input-plaintext-color: $body-color; $input-plaintext-color: $input-plaintext-color !global; // $body-color

  // $input-height-border: $input-border-width * 2; $input-height-border: $input-height-border !global; // $input-border-width * 2

  // $input-height-inner: add($input-line-height * 1em, $input-padding-y * 2); $input-height-inner: $input-height-inner !global; // add($input-line-height * 1em, $input-padding-y * 2)
  // $input-height-inner-half: add($input-line-height * .5em, $input-padding-y); $input-height-inner-half: $input-height-inner-half !global; // add($input-line-height * .5em, $input-padding-y)
  // $input-height-inner-quarter: add($input-line-height * .25em, $input-padding-y / 2); $input-height-inner-quarter: $input-height-inner-quarter !global; // add($input-line-height * .25em, $input-padding-y / 2)

  // $input-height: add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false)); $input-height: $input-height !global; // add($input-line-height * 1em, add($input-padding-y * 2, $input-height-border, false))
  // $input-height-sm: add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false)); $input-height-sm: $input-height-sm !global; // add($input-line-height-sm * 1em, add($input-padding-y-sm * 2, $input-height-border, false))
  // $input-height-lg: add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false)); $input-height-lg: $input-height-lg !global; // add($input-line-height-lg * 1em, add($input-padding-y-lg * 2, $input-height-border, false))

  // $input-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out; $input-transition: $input-transition !global; // border-color .15s ease-in-out, box-shadow .15s ease-in-out

  // $form-text-margin-top: .25rem; $form-text-margin-top: $form-text-margin-top !global; // .25rem

  // $form-check-input-gutter: 1.25rem; $form-check-input-gutter: $form-check-input-gutter !global; // 1.25rem
  // $form-check-input-margin-y: .3rem; $form-check-input-margin-y: $form-check-input-margin-y !global; // .3rem
  // $form-check-input-margin-x: .25rem; $form-check-input-margin-x: $form-check-input-margin-x !global; // .25rem

  // $form-check-inline-margin-x: .75rem; $form-check-inline-margin-x: $form-check-inline-margin-x !global; // .75rem
  // $form-check-inline-input-margin-x: .3125rem; $form-check-inline-input-margin-x: $form-check-inline-input-margin-x !global; // .3125rem

  // $form-grid-gutter-width: 10px; $form-grid-gutter-width: $form-grid-gutter-width !global; // 10px
  // $form-group-margin-bottom: 1rem; $form-group-margin-bottom: $form-group-margin-bottom !global; // 1rem

  // $input-group-addon-color: $input-color; $input-group-addon-color: $input-group-addon-color !global; // $input-color
  // $input-group-addon-bg: $gray-200; $input-group-addon-bg: $input-group-addon-bg !global; // $gray-200
  // $input-group-addon-border-color: $input-border-color; $input-group-addon-border-color: $input-group-addon-border-color !global; // $input-border-color

  // $custom-forms-transition: background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out; $custom-forms-transition: $custom-forms-transition !global; // background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out

  // $custom-control-gutter: .5rem; $custom-control-gutter: $custom-control-gutter !global; // .5rem
  // $custom-control-spacer-x: 1rem; $custom-control-spacer-x: $custom-control-spacer-x !global; // 1rem
  // $custom-control-cursor: null; $custom-control-cursor: $custom-control-cursor !global; // null

  // $custom-control-indicator-size: 1rem; $custom-control-indicator-size: $custom-control-indicator-size !global; // 1rem
  // $custom-control-indicator-bg: $input-bg; $custom-control-indicator-bg: $custom-control-indicator-bg !global; // $input-bg

  // $custom-control-indicator-bg-size: 50% 50%; $custom-control-indicator-bg-size: $custom-control-indicator-bg-size !global; // 50% 50%
  // $custom-control-indicator-box-shadow: $input-box-shadow; $custom-control-indicator-box-shadow: $custom-control-indicator-box-shadow !global; // $input-box-shadow
  // $custom-control-indicator-border-color: $gray-500; $custom-control-indicator-border-color: $custom-control-indicator-border-color !global; // $gray-500
  // $custom-control-indicator-border-width: $input-border-width; $custom-control-indicator-border-width: $custom-control-indicator-border-width !global; // $input-border-width

  // $custom-control-label-color: null; $custom-control-label-color: $custom-control-label-color !global; // null

  // $custom-control-indicator-disabled-bg: $input-disabled-bg; $custom-control-indicator-disabled-bg: $custom-control-indicator-disabled-bg !global; // $input-disabled-bg
  // $custom-control-label-disabled-color: $gray-600; $custom-control-label-disabled-color: $custom-control-label-disabled-color !global; // $gray-600

  // $custom-control-indicator-checked-color: $component-active-color; $custom-control-indicator-checked-color: $custom-control-indicator-checked-color !global; // $component-active-color
  // $custom-control-indicator-checked-bg: $component-active-bg; $custom-control-indicator-checked-bg: $custom-control-indicator-checked-bg !global; // $component-active-bg
  // $custom-control-indicator-checked-disabled-bg: rgba(theme-color("primary"), .5); $custom-control-indicator-checked-disabled-bg: $custom-control-indicator-checked-disabled-bg !global; // rgba(theme-color("primary"), .5)
  // $custom-control-indicator-checked-box-shadow: none; $custom-control-indicator-checked-box-shadow: $custom-control-indicator-checked-box-shadow !global; // none
  // $custom-control-indicator-checked-border-color: $custom-control-indicator-checked-bg; $custom-control-indicator-checked-border-color: $custom-control-indicator-checked-border-color !global; // $custom-control-indicator-checked-bg

  // $custom-control-indicator-focus-box-shadow: $input-focus-box-shadow; $custom-control-indicator-focus-box-shadow: $custom-control-indicator-focus-box-shadow !global; // $input-focus-box-shadow
  // $custom-control-indicator-focus-border-color: $input-focus-border-color; $custom-control-indicator-focus-border-color: $custom-control-indicator-focus-border-color !global; // $input-focus-border-color

  // $custom-control-indicator-active-color: $component-active-color; $custom-control-indicator-active-color: $custom-control-indicator-active-color !global; // $component-active-color
  // $custom-control-indicator-active-bg: lighten($component-active-bg, 35%); $custom-control-indicator-active-bg: $custom-control-indicator-active-bg !global; // lighten($component-active-bg, 35%)
  // $custom-control-indicator-active-box-shadow: none; $custom-control-indicator-active-box-shadow: $custom-control-indicator-active-box-shadow !global; // none
  // $custom-control-indicator-active-border-color: $custom-control-indicator-active-bg; $custom-control-indicator-active-border-color: $custom-control-indicator-active-border-color !global; // $custom-control-indicator-active-bg

  // $custom-checkbox-indicator-border-radius: $border-radius; $custom-checkbox-indicator-border-radius: $custom-checkbox-indicator-border-radius !global; // $border-radius
  // $custom-checkbox-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>"); $custom-checkbox-indicator-icon-checked: $custom-checkbox-indicator-icon-checked !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/></svg>")

  // $custom-checkbox-indicator-indeterminate-bg: $component-active-bg; $custom-checkbox-indicator-indeterminate-bg: $custom-checkbox-indicator-indeterminate-bg !global; // $component-active-bg
  // $custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color; $custom-checkbox-indicator-indeterminate-color: $custom-checkbox-indicator-indeterminate-color !global; // $custom-control-indicator-checked-color
  // $custom-checkbox-indicator-icon-indeterminate: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>"); $custom-checkbox-indicator-icon-indeterminate: $custom-checkbox-indicator-icon-indeterminate !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'><path stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/></svg>")
  // $custom-checkbox-indicator-indeterminate-box-shadow: none; $custom-checkbox-indicator-indeterminate-box-shadow: $custom-checkbox-indicator-indeterminate-box-shadow !global; // none
  // $custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg; $custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-border-color !global; // $custom-checkbox-indicator-indeterminate-bg

  // $custom-radio-indicator-border-radius: 50%; $custom-radio-indicator-border-radius: $custom-radio-indicator-border-radius !global; // 50%
  // $custom-radio-indicator-icon-checked: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>"); $custom-radio-indicator-icon-checked: $custom-radio-indicator-icon-checked !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'><circle r='3' fill='#{$custom-control-indicator-checked-color}'/></svg>")

  // $custom-switch-width: $custom-control-indicator-size * 1.75; $custom-switch-width: $custom-switch-width !global; // $custom-control-indicator-size * 1.75
  // $custom-switch-indicator-border-radius: $custom-control-indicator-size / 2; $custom-switch-indicator-border-radius: $custom-switch-indicator-border-radius !global; // $custom-control-indicator-size / 2
  // $custom-switch-indicator-size: subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4); $custom-switch-indicator-size: $custom-switch-indicator-size !global; // subtract($custom-control-indicator-size, $custom-control-indicator-border-width * 4)

  // $custom-select-padding-y: $input-padding-y; $custom-select-padding-y: $custom-select-padding-y !global; // $input-padding-y
  // $custom-select-padding-x: $input-padding-x; $custom-select-padding-x: $custom-select-padding-x !global; // $input-padding-x
  // $custom-select-font-family: $input-font-family; $custom-select-font-family: $custom-select-font-family !global; // $input-font-family
  // $custom-select-font-size: $input-font-size; $custom-select-font-size: $custom-select-font-size !global; // $input-font-size
  // $custom-select-height: $input-height; $custom-select-height: $custom-select-height !global; // $input-height
  // $custom-select-indicator-padding: 1rem; $custom-select-indicator-padding: $custom-select-indicator-padding !global; // 1rem // Extra padding to account for the presence of the background-image based indicator
  // $custom-select-font-weight: $input-font-weight; $custom-select-font-weight: $custom-select-font-weight !global; // $input-font-weight
  // $custom-select-line-height: $input-line-height; $custom-select-line-height: $custom-select-line-height !global; // $input-line-height
  // $custom-select-color: $input-color; $custom-select-color: $custom-select-color !global; // $input-color
  // $custom-select-disabled-color: $gray-600; $custom-select-disabled-color: $custom-select-disabled-color !global; // $gray-600
  // $custom-select-bg: $input-bg; $custom-select-bg: $custom-select-bg !global; // $input-bg
  // $custom-select-disabled-bg: $gray-200; $custom-select-disabled-bg: $custom-select-disabled-bg !global; // $gray-200
  // $custom-select-bg-size: 8px 10px; $custom-select-bg-size: $custom-select-bg-size !global; // 8px 10px // In pixels because image dimensions
  // $custom-select-indicator-color: $gray-800; $custom-select-indicator-color: $custom-select-indicator-color !global; // $gray-800
  // $custom-select-indicator: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>"); $custom-select-indicator: $custom-select-indicator !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'><path fill='#{$custom-select-indicator-color}' d='M2 0L0 2h4zm0 5L0 3h4z'/></svg>")
  // $custom-select-background: escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size; $custom-select-background: $custom-select-background !global; // escape-svg($custom-select-indicator) no-repeat right $custom-select-padding-x center / $custom-select-bg-size // Used so we can have multiple background elements (e.g., arrow and feedback icon)

  // $custom-select-feedback-icon-padding-right: add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding); $custom-select-feedback-icon-padding-right: $custom-select-feedback-icon-padding-right !global; // add(1em * .75, (2 * $custom-select-padding-y * .75) + $custom-select-padding-x + $custom-select-indicator-padding)
  // $custom-select-feedback-icon-position: center right ($custom-select-padding-x + $custom-select-indicator-padding); $custom-select-feedback-icon-position: $custom-select-feedback-icon-position !global; // center right ($custom-select-padding-x + $custom-select-indicator-padding)
  // $custom-select-feedback-icon-size: $input-height-inner-half $input-height-inner-half; $custom-select-feedback-icon-size: $custom-select-feedback-icon-size !global; // $input-height-inner-half $input-height-inner-half

  // $custom-select-border-width: $input-border-width; $custom-select-border-width: $custom-select-border-width !global; // $input-border-width
  // $custom-select-border-color: $input-border-color; $custom-select-border-color: $custom-select-border-color !global; // $input-border-color
  // $custom-select-border-radius: $border-radius; $custom-select-border-radius: $custom-select-border-radius !global; // $border-radius
  // $custom-select-box-shadow: inset 0 1px 2px rgba($black, .075); $custom-select-box-shadow: $custom-select-box-shadow !global; // inset 0 1px 2px rgba($black, .075)

  // $custom-select-focus-border-color: $input-focus-border-color; $custom-select-focus-border-color: $custom-select-focus-border-color !global; // $input-focus-border-color
  // $custom-select-focus-width: $input-focus-width; $custom-select-focus-width: $custom-select-focus-width !global; // $input-focus-width
  // $custom-select-focus-box-shadow: 0 0 0 $custom-select-focus-width $input-btn-focus-color; $custom-select-focus-box-shadow: $custom-select-focus-box-shadow !global; // 0 0 0 $custom-select-focus-width $input-btn-focus-color

  // $custom-select-padding-y-sm: $input-padding-y-sm; $custom-select-padding-y-sm: $custom-select-padding-y-sm !global; // $input-padding-y-sm
  // $custom-select-padding-x-sm: $input-padding-x-sm; $custom-select-padding-x-sm: $custom-select-padding-x-sm !global; // $input-padding-x-sm
  // $custom-select-font-size-sm: $input-font-size-sm; $custom-select-font-size-sm: $custom-select-font-size-sm !global; // $input-font-size-sm
  // $custom-select-height-sm: $input-height-sm; $custom-select-height-sm: $custom-select-height-sm !global; // $input-height-sm

  // $custom-select-padding-y-lg: $input-padding-y-lg; $custom-select-padding-y-lg: $custom-select-padding-y-lg !global; // $input-padding-y-lg
  // $custom-select-padding-x-lg: $input-padding-x-lg; $custom-select-padding-x-lg: $custom-select-padding-x-lg !global; // $input-padding-x-lg
  // $custom-select-font-size-lg: $input-font-size-lg; $custom-select-font-size-lg: $custom-select-font-size-lg !global; // $input-font-size-lg
  // $custom-select-height-lg: $input-height-lg; $custom-select-height-lg: $custom-select-height-lg !global; // $input-height-lg

  // $custom-range-track-width: 100%; $custom-range-track-width: $custom-range-track-width !global; // 100%
  // $custom-range-track-height: .5rem; $custom-range-track-height: $custom-range-track-height !global; // .5rem
  // $custom-range-track-cursor: pointer; $custom-range-track-cursor: $custom-range-track-cursor !global; // pointer
  // $custom-range-track-bg: $gray-300; $custom-range-track-bg: $custom-range-track-bg !global; // $gray-300
  // $custom-range-track-border-radius: 1rem; $custom-range-track-border-radius: $custom-range-track-border-radius !global; // 1rem
  // $custom-range-track-box-shadow: inset 0 .25rem .25rem rgba($black, .1); $custom-range-track-box-shadow: $custom-range-track-box-shadow !global; // inset 0 .25rem .25rem rgba($black, .1)

  // $custom-range-thumb-width: 1rem; $custom-range-thumb-width: $custom-range-thumb-width !global; // 1rem
  // $custom-range-thumb-height: $custom-range-thumb-width; $custom-range-thumb-height: $custom-range-thumb-height !global; // $custom-range-thumb-width
  // $custom-range-thumb-bg: $component-active-bg; $custom-range-thumb-bg: $custom-range-thumb-bg !global; // $component-active-bg
  // $custom-range-thumb-border: 0; $custom-range-thumb-border: $custom-range-thumb-border !global; // 0
  // $custom-range-thumb-border-radius: 1rem; $custom-range-thumb-border-radius: $custom-range-thumb-border-radius !global; // 1rem
  // $custom-range-thumb-box-shadow: 0 .1rem .25rem rgba($black, .1); $custom-range-thumb-box-shadow: $custom-range-thumb-box-shadow !global; // 0 .1rem .25rem rgba($black, .1)
  // $custom-range-thumb-focus-box-shadow: 0 0 0 1px $body-bg, $input-focus-box-shadow; $custom-range-thumb-focus-box-shadow: $custom-range-thumb-focus-box-shadow !global; // 0 0 0 1px $body-bg, $input-focus-box-shadow
  // $custom-range-thumb-focus-box-shadow-width: $input-focus-width; $custom-range-thumb-focus-box-shadow-width: $custom-range-thumb-focus-box-shadow-width !global; // $input-focus-width // For focus box shadow issue in IE/Edge
  // $custom-range-thumb-active-bg: lighten($component-active-bg, 35%); $custom-range-thumb-active-bg: $custom-range-thumb-active-bg !global; // lighten($component-active-bg, 35%)
  // $custom-range-thumb-disabled-bg: $gray-500; $custom-range-thumb-disabled-bg: $custom-range-thumb-disabled-bg !global; // $gray-500

  // $custom-file-height: $input-height; $custom-file-height: $custom-file-height !global; // $input-height
  // $custom-file-height-inner: $input-height-inner; $custom-file-height-inner: $custom-file-height-inner !global; // $input-height-inner
  // $custom-file-focus-border-color: $input-focus-border-color; $custom-file-focus-border-color: $custom-file-focus-border-color !global; // $input-focus-border-color
  // $custom-file-focus-box-shadow: $input-focus-box-shadow; $custom-file-focus-box-shadow: $custom-file-focus-box-shadow !global; // $input-focus-box-shadow
  // $custom-file-disabled-bg: $input-disabled-bg; $custom-file-disabled-bg: $custom-file-disabled-bg !global; // $input-disabled-bg

  // $custom-file-padding-y: $input-padding-y; $custom-file-padding-y: $custom-file-padding-y !global; // $input-padding-y
  // $custom-file-padding-x: $input-padding-x; $custom-file-padding-x: $custom-file-padding-x !global; // $input-padding-x
  // $custom-file-line-height: $input-line-height; $custom-file-line-height: $custom-file-line-height !global; // $input-line-height
  // $custom-file-font-family: $input-font-family; $custom-file-font-family: $custom-file-font-family !global; // $input-font-family
  // $custom-file-font-weight: $input-font-weight; $custom-file-font-weight: $custom-file-font-weight !global; // $input-font-weight
  // $custom-file-color: $input-color; $custom-file-color: $custom-file-color !global; // $input-color
  // $custom-file-bg: $input-bg; $custom-file-bg: $custom-file-bg !global; // $input-bg
  // $custom-file-border-width: $input-border-width; $custom-file-border-width: $custom-file-border-width !global; // $input-border-width
  // $custom-file-border-color: $input-border-color; $custom-file-border-color: $custom-file-border-color !global; // $input-border-color
  // $custom-file-border-radius: $input-border-radius; $custom-file-border-radius: $custom-file-border-radius !global; // $input-border-radius
  // $custom-file-box-shadow: $input-box-shadow; $custom-file-box-shadow: $custom-file-box-shadow !global; // $input-box-shadow
  // $custom-file-button-color: $custom-file-color; $custom-file-button-color: $custom-file-button-color !global; // $custom-file-color
  // $custom-file-button-bg: $input-group-addon-bg; $custom-file-button-bg: $custom-file-button-bg !global; // $input-group-addon-bg
  // $custom-file-text: (en: "Browse"); $custom-file-text: $custom-file-text !global; // en: "Browse"


  // Form validation

  // $form-feedback-margin-top: $form-text-margin-top; $form-feedback-margin-top: $form-feedback-margin-top !global; // $form-text-margin-top
  // $form-feedback-font-size: $small-font-size; $form-feedback-font-size: $form-feedback-font-size !global; // $small-font-size
  // $form-feedback-valid-color: theme-color("success"); $form-feedback-valid-color: $form-feedback-valid-color !global; // theme-color("success")
  // $form-feedback-invalid-color: theme-color("danger"); $form-feedback-invalid-color: $form-feedback-invalid-color !global; // theme-color("danger")

  // $form-feedback-icon-valid-color: $form-feedback-valid-color; $form-feedback-icon-valid-color: $form-feedback-icon-valid-color !global; // $form-feedback-valid-color
  // $form-feedback-icon-valid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>"); $form-feedback-icon-valid: $form-feedback-icon-valid !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'><path fill='#{$form-feedback-icon-valid-color}' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/></svg>")
  // $form-feedback-icon-invalid-color: $form-feedback-invalid-color; $form-feedback-icon-invalid-color: $form-feedback-icon-invalid-color !global; // $form-feedback-invalid-color
  // $form-feedback-icon-invalid: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>"); $form-feedback-icon-invalid: $form-feedback-icon-invalid !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='#{$form-feedback-icon-invalid-color}' viewBox='0 0 12 12'><circle cx='6' cy='6' r='4.5'/><path stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/><circle cx='6' cy='8.2' r='.6' fill='#{$form-feedback-icon-invalid-color}' stroke='none'/></svg>")

  // uncomment whole block, the following variable uses map-merge(),
  // so you can define only new ones you need
  /*
    // $form-validation-states: (); $form-validation-states: $form-validation-states !global;
    // stylelint-disable-next-line scss/dollar-variable-default
    $form-validation-states: (
    //        "valid": (
    //          "color": $form-feedback-valid-color,
    //          "icon": $form-feedback-icon-valid
    //        ),
    //        "invalid": (
    //          "color": $form-feedback-invalid-color,
    //          "icon": $form-feedback-icon-invalid
    //        ),
    ); $form-validation-states: $form-validation-states !global;
    */

  // Z-index master list
  //
  // Warning: Avoid customizing these values. They're used for a bird's eye view
  // of components dependent on the z-axis and are designed to all work together.

  // $zindex-dropdown: 1000; $zindex-dropdown: $zindex-dropdown !global; // 1000
  // $zindex-sticky: 1020; $zindex-sticky: $zindex-sticky !global; // 1020
  // $zindex-fixed: 1030; $zindex-fixed: $zindex-fixed !global; // 1030
  // $zindex-modal-backdrop: 1040; $zindex-modal-backdrop: $zindex-modal-backdrop !global; // 1040
  // $zindex-modal: 1050; $zindex-modal: $zindex-modal !global; // 1050
  // $zindex-popover: 1060; $zindex-popover: $zindex-popover !global; // 1060
  // $zindex-tooltip: 1070; $zindex-tooltip: $zindex-tooltip !global; // 1070


  // Navs

  // $nav-link-padding-y: .5rem; $nav-link-padding-y: $nav-link-padding-y !global; // .5rem
  // $nav-link-padding-x: 1rem; $nav-link-padding-x: $nav-link-padding-x !global; // 1rem
  // $nav-link-disabled-color: $gray-600; $nav-link-disabled-color: $nav-link-disabled-color !global; // $gray-600

  // $nav-tabs-border-color: $gray-300; $nav-tabs-border-color: $nav-tabs-border-color !global; // $gray-300
  // $nav-tabs-border-width: $border-width; $nav-tabs-border-width: $nav-tabs-border-width !global; // $border-width
  // $nav-tabs-border-radius: $border-radius; $nav-tabs-border-radius: $nav-tabs-border-radius !global; // $border-radius
  // $nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color; $nav-tabs-link-hover-border-color: $nav-tabs-link-hover-border-color !global; // $gray-200 $gray-200 $nav-tabs-border-color
  // $nav-tabs-link-active-color: $gray-700; $nav-tabs-link-active-color: $nav-tabs-link-active-color !global; // $gray-700
  // $nav-tabs-link-active-bg: $body-bg; $nav-tabs-link-active-bg: $nav-tabs-link-active-bg !global; // $body-bg
  // $nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg; $nav-tabs-link-active-border-color: $nav-tabs-link-active-border-color !global; // $gray-300 $gray-300 $nav-tabs-link-active-bg

  // $nav-pills-border-radius: $border-radius; $nav-pills-border-radius: $nav-pills-border-radius !global; // $border-radius
  // $nav-pills-link-active-color: $component-active-color; $nav-pills-link-active-color: $nav-pills-link-active-color !global; // $component-active-color
  // $nav-pills-link-active-bg: $component-active-bg; $nav-pills-link-active-bg: $nav-pills-link-active-bg !global; // $component-active-bg

  // $nav-divider-color: $gray-200; $nav-divider-color: $nav-divider-color !global; // $gray-200
  // $nav-divider-margin-y: $spacer / 2; $nav-divider-margin-y: $nav-divider-margin-y !global; // $spacer / 2


  // Navbar

  // $navbar-padding-y: $spacer / 2; $navbar-padding-y: $navbar-padding-y !global; // $spacer / 2
  // $navbar-padding-x: $spacer; $navbar-padding-x: $navbar-padding-x !global; // $spacer

  // $navbar-nav-link-padding-x: .5rem; $navbar-nav-link-padding-x: $navbar-nav-link-padding-x !global; // .5rem

  // $navbar-brand-font-size: $font-size-lg; $navbar-brand-font-size: $navbar-brand-font-size !global; // $font-size-lg
  // Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
  // $nav-link-height: $font-size-base * $line-height-base + $nav-link-padding-y * 2; $nav-link-height: $nav-link-height !global; // $font-size-base * $line-height-base + $nav-link-padding-y * 2
  // $navbar-brand-height: $navbar-brand-font-size * $line-height-base; $navbar-brand-height: $navbar-brand-height !global; // $navbar-brand-font-size * $line-height-base
  // $navbar-brand-padding-y: ($nav-link-height - $navbar-brand-height) / 2; $navbar-brand-padding-y: $navbar-brand-padding-y !global; // ($nav-link-height - $navbar-brand-height) / 2

  // $navbar-toggler-padding-y: .25rem; $navbar-toggler-padding-y: $navbar-toggler-padding-y !global; // .25rem
  // $navbar-toggler-padding-x: .75rem; $navbar-toggler-padding-x: $navbar-toggler-padding-x !global; // .75rem
  // $navbar-toggler-font-size: $font-size-lg; $navbar-toggler-font-size: $navbar-toggler-font-size !global; // $font-size-lg
  // $navbar-toggler-border-radius: $btn-border-radius; $navbar-toggler-border-radius: $navbar-toggler-border-radius !global; // $btn-border-radius

  // $navbar-dark-color: rgba($white, .5); $navbar-dark-color: $navbar-dark-color !global; // rgba($white, .5)
  // $navbar-dark-hover-color: rgba($white, .75); $navbar-dark-hover-color: $navbar-dark-hover-color !global; // rgba($white, .75)
  // $navbar-dark-active-color: $white; $navbar-dark-active-color: $navbar-dark-active-color !global; // $white
  // $navbar-dark-disabled-color: rgba($white, .25); $navbar-dark-disabled-color: $navbar-dark-disabled-color !global; // rgba($white, .25)
  // $navbar-dark-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"); $navbar-dark-toggler-icon-bg: $navbar-dark-toggler-icon-bg !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
  // $navbar-dark-toggler-border-color: rgba($white, .1); $navbar-dark-toggler-border-color: $navbar-dark-toggler-border-color !global; // rgba($white, .1)

  // $navbar-light-color: rgba($black, .5); $navbar-light-color: $navbar-light-color !global; // rgba($black, .5)
  // $navbar-light-hover-color: rgba($black, .7); $navbar-light-hover-color: $navbar-light-hover-color !global; // rgba($black, .7)
  // $navbar-light-active-color: rgba($black, .9); $navbar-light-active-color: $navbar-light-active-color !global; // rgba($black, .9)
  // $navbar-light-disabled-color: rgba($black, .3); $navbar-light-disabled-color: $navbar-light-disabled-color !global; // rgba($black, .3)
  // $navbar-light-toggler-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>"); $navbar-light-toggler-icon-bg: $navbar-light-toggler-icon-bg !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>")
  // $navbar-light-toggler-border-color: rgba($black, .1); $navbar-light-toggler-border-color: $navbar-light-toggler-border-color !global; // rgba($black, .1)

  // $navbar-light-brand-color: $navbar-light-active-color; $navbar-light-brand-color: $navbar-light-brand-color !global; // $navbar-light-active-color
  // $navbar-light-brand-hover-color: $navbar-light-active-color; $navbar-light-brand-hover-color: $navbar-light-brand-hover-color !global; // $navbar-light-active-color
  // $navbar-dark-brand-color: $navbar-dark-active-color; $navbar-dark-brand-color: $navbar-dark-brand-color !global; // $navbar-dark-active-color
  // $navbar-dark-brand-hover-color: $navbar-dark-active-color; $navbar-dark-brand-hover-color: $navbar-dark-brand-hover-color !global; // $navbar-dark-active-color


  // Dropdowns
  //
  // Dropdown menu container and contents.

  // $dropdown-min-width: 10rem; $dropdown-min-width: $dropdown-min-width !global; // 10rem
  // $dropdown-padding-y: .5rem; $dropdown-padding-y: $dropdown-padding-y !global; // .5rem
  // $dropdown-spacer: .125rem; $dropdown-spacer: $dropdown-spacer !global; // .125rem
  // $dropdown-font-size: $font-size-base; $dropdown-font-size: $dropdown-font-size !global; // $font-size-base
  // $dropdown-color: $body-color; $dropdown-color: $dropdown-color !global; // $body-color
  // $dropdown-bg: $white; $dropdown-bg: $dropdown-bg !global; // $white
  // $dropdown-border-color: rgba($black, .15); $dropdown-border-color: $dropdown-border-color !global; // rgba($black, .15)
  // $dropdown-border-radius: $border-radius; $dropdown-border-radius: $dropdown-border-radius !global; // $border-radius
  // $dropdown-border-width: $border-width; $dropdown-border-width: $dropdown-border-width !global; // $border-width
  // $dropdown-inner-border-radius: subtract($dropdown-border-radius, $dropdown-border-width); $dropdown-inner-border-radius: $dropdown-inner-border-radius !global; // subtract($dropdown-border-radius, $dropdown-border-width)
  // $dropdown-divider-bg: $gray-200; $dropdown-divider-bg: $dropdown-divider-bg !global; // $gray-200
  // $dropdown-divider-margin-y: $nav-divider-margin-y; $dropdown-divider-margin-y: $dropdown-divider-margin-y !global; // $nav-divider-margin-y
  // $dropdown-box-shadow: 0 .5rem 1rem rgba($black, .175); $dropdown-box-shadow: $dropdown-box-shadow !global; // 0 .5rem 1rem rgba($black, .175)

  // $dropdown-link-color: $gray-900; $dropdown-link-color: $dropdown-link-color !global; // $gray-900
  // $dropdown-link-hover-color: darken($gray-900, 5%); $dropdown-link-hover-color: $dropdown-link-hover-color !global; // darken($gray-900, 5%)
  // $dropdown-link-hover-bg: $gray-100; $dropdown-link-hover-bg: $dropdown-link-hover-bg !global; // $gray-100

  // $dropdown-link-active-color: $component-active-color; $dropdown-link-active-color: $dropdown-link-active-color !global; // $component-active-color
  // $dropdown-link-active-bg: $component-active-bg; $dropdown-link-active-bg: $dropdown-link-active-bg !global; // $component-active-bg

  // $dropdown-link-disabled-color: $gray-600; $dropdown-link-disabled-color: $dropdown-link-disabled-color !global; // $gray-600

  // $dropdown-item-padding-y: .25rem; $dropdown-item-padding-y: $dropdown-item-padding-y !global; // .25rem
  // $dropdown-item-padding-x: 1.5rem; $dropdown-item-padding-x: $dropdown-item-padding-x !global; // 1.5rem

  // $dropdown-header-color: $gray-600; $dropdown-header-color: $dropdown-header-color !global; // $gray-600
  // $dropdown-header-padding: $dropdown-padding-y $dropdown-item-padding-x; $dropdown-header-padding: $dropdown-header-padding !global; // $dropdown-padding-y $dropdown-item-padding-x


  // Pagination

  // $pagination-padding-y: .5rem; $pagination-padding-y: $pagination-padding-y !global; // .5rem
  // $pagination-padding-x: .75rem; $pagination-padding-x: $pagination-padding-x !global; // .75rem
  // $pagination-padding-y-sm: .25rem; $pagination-padding-y-sm: $pagination-padding-y-sm !global; // .25rem
  // $pagination-padding-x-sm: .5rem; $pagination-padding-x-sm: $pagination-padding-x-sm !global; // .5rem
  // $pagination-padding-y-lg: .75rem; $pagination-padding-y-lg: $pagination-padding-y-lg !global; // .75rem
  // $pagination-padding-x-lg: 1.5rem; $pagination-padding-x-lg: $pagination-padding-x-lg !global; // 1.5rem
  // $pagination-line-height: 1.25; $pagination-line-height: $pagination-line-height !global; // 1.25

  // $pagination-color: $link-color; $pagination-color: $pagination-color !global; // $link-color
  // $pagination-bg: $white; $pagination-bg: $pagination-bg !global; // $white
  // $pagination-border-width: $border-width; $pagination-border-width: $pagination-border-width !global; // $border-width
  // $pagination-border-color: $gray-300; $pagination-border-color: $pagination-border-color !global; // $gray-300

  // $pagination-focus-box-shadow: $input-btn-focus-box-shadow; $pagination-focus-box-shadow: $pagination-focus-box-shadow !global; // $input-btn-focus-box-shadow
  // $pagination-focus-outline: 0; $pagination-focus-outline: $pagination-focus-outline !global; // 0

  // $pagination-hover-color: $link-hover-color; $pagination-hover-color: $pagination-hover-color !global; // $link-hover-color
  // $pagination-hover-bg: $gray-200; $pagination-hover-bg: $pagination-hover-bg !global; // $gray-200
  // $pagination-hover-border-color: $gray-300; $pagination-hover-border-color: $pagination-hover-border-color !global; // $gray-300

  // $pagination-active-color: $component-active-color; $pagination-active-color: $pagination-active-color !global; // $component-active-color
  // $pagination-active-bg: $component-active-bg; $pagination-active-bg: $pagination-active-bg !global; // $component-active-bg
  // $pagination-active-border-color: $pagination-active-bg; $pagination-active-border-color: $pagination-active-border-color !global; // $pagination-active-bg

  // $pagination-disabled-color: $gray-600; $pagination-disabled-color: $pagination-disabled-color !global; // $gray-600
  // $pagination-disabled-bg: $white; $pagination-disabled-bg: $pagination-disabled-bg !global; // $white
  // $pagination-disabled-border-color: $gray-300; $pagination-disabled-border-color: $pagination-disabled-border-color !global; // $gray-300


  // Jumbotron

  // $jumbotron-padding: 2rem; $jumbotron-padding: $jumbotron-padding !global; // 2rem
  // $jumbotron-color: null; $jumbotron-color: $jumbotron-color !global; // null
  // $jumbotron-bg: $gray-200; $jumbotron-bg: $jumbotron-bg !global; // $gray-200


  // Cards

  // $card-spacer-y: .75rem; $card-spacer-y: $card-spacer-y !global; // .75rem
  // $card-spacer-x: 1.25rem; $card-spacer-x: $card-spacer-x !global; // 1.25rem
  // $card-border-width: $border-width; $card-border-width: $card-border-width !global; // $border-width
  // $card-border-radius: $border-radius; $card-border-radius: $card-border-radius !global; // $border-radius
  // $card-border-color: rgba($black, .125); $card-border-color: $card-border-color !global; // rgba($black, .125)
  // $card-inner-border-radius: subtract($card-border-radius, $card-border-width); $card-inner-border-radius: $card-inner-border-radius !global; // subtract($card-border-radius, $card-border-width)
  // $card-cap-bg: rgba($black, .03); $card-cap-bg: $card-cap-bg !global; // rgba($black, .03)
  // $card-cap-color: null; $card-cap-color: $card-cap-color !global; // null
  // $card-height: null; $card-height: $card-height !global; // null
  // $card-color: null; $card-color: $card-color !global; // null
  // $card-bg: $white; $card-bg: $card-bg !global; // $white

  // $card-img-overlay-padding: 1.25rem; $card-img-overlay-padding: $card-img-overlay-padding !global; // 1.25rem

  // $card-group-margin: $grid-gutter-width / 2; $card-group-margin: $card-group-margin !global; // $grid-gutter-width / 2
  // $card-deck-margin: $card-group-margin; $card-deck-margin: $card-deck-margin !global; // $card-group-margin

  // $card-columns-count: 3; $card-columns-count: $card-columns-count !global; // 3
  // $card-columns-gap: 1.25rem; $card-columns-gap: $card-columns-gap !global; // 1.25rem
  // $card-columns-margin: $card-spacer-y; $card-columns-margin: $card-columns-margin !global; // $card-spacer-y


  // Tooltips

  // $tooltip-font-size: $font-size-sm; $tooltip-font-size: $tooltip-font-size !global; // $font-size-sm
  // $tooltip-max-width: 200px; $tooltip-max-width: $tooltip-max-width !global; // 200px
  // $tooltip-color: $white; $tooltip-color: $tooltip-color !global; // $white
  // $tooltip-bg: $black; $tooltip-bg: $tooltip-bg !global; // $black
  // $tooltip-border-radius: $border-radius; $tooltip-border-radius: $tooltip-border-radius !global; // $border-radius
  // $tooltip-opacity: .9; $tooltip-opacity: $tooltip-opacity !global; // .9
  // $tooltip-padding-y: .25rem; $tooltip-padding-y: $tooltip-padding-y !global; // .25rem
  // $tooltip-padding-x: .5rem; $tooltip-padding-x: $tooltip-padding-x !global; // .5rem
  // $tooltip-margin: 0; $tooltip-margin: $tooltip-margin !global; // 0

  // $tooltip-arrow-width: .8rem; $tooltip-arrow-width: $tooltip-arrow-width !global; // .8rem
  // $tooltip-arrow-height: .4rem; $tooltip-arrow-height: $tooltip-arrow-height !global; // .4rem
  // $tooltip-arrow-color: $tooltip-bg; $tooltip-arrow-color: $tooltip-arrow-color !global; // $tooltip-bg

  // Form tooltips must come after regular tooltips
  // $form-feedback-tooltip-padding-y: $tooltip-padding-y; $form-feedback-tooltip-padding-y: $form-feedback-tooltip-padding-y !global; // $tooltip-padding-y
  // $form-feedback-tooltip-padding-x: $tooltip-padding-x; $form-feedback-tooltip-padding-x: $form-feedback-tooltip-padding-x !global; // $tooltip-padding-x
  // $form-feedback-tooltip-font-size: $tooltip-font-size; $form-feedback-tooltip-font-size: $form-feedback-tooltip-font-size !global; // $tooltip-font-size
  // $form-feedback-tooltip-line-height: $line-height-base; $form-feedback-tooltip-line-height: $form-feedback-tooltip-line-height !global; // $line-height-base
  // $form-feedback-tooltip-opacity: $tooltip-opacity; $form-feedback-tooltip-opacity: $form-feedback-tooltip-opacity !global; // $tooltip-opacity
  // $form-feedback-tooltip-border-radius: $tooltip-border-radius; $form-feedback-tooltip-border-radius: $form-feedback-tooltip-border-radius !global; // $tooltip-border-radius


  // Popovers

  // $popover-font-size: $font-size-sm; $popover-font-size: $popover-font-size !global; // $font-size-sm
  // $popover-bg: $white; $popover-bg: $popover-bg !global; // $white
  // $popover-max-width: 276px; $popover-max-width: $popover-max-width !global; // 276px
  // $popover-border-width: $border-width; $popover-border-width: $popover-border-width !global; // $border-width
  // $popover-border-color: rgba($black, .2); $popover-border-color: $popover-border-color !global; // rgba($black, .2)
  // $popover-border-radius: $border-radius-lg; $popover-border-radius: $popover-border-radius !global; // $border-radius-lg
  // $popover-inner-border-radius: subtract($popover-border-radius, $popover-border-width); $popover-inner-border-radius: $popover-inner-border-radius !global; // subtract($popover-border-radius, $popover-border-width)
  // $popover-box-shadow: 0 .25rem .5rem rgba($black, .2); $popover-box-shadow: $popover-box-shadow !global; // 0 .25rem .5rem rgba($black, .2)

  // $popover-header-bg: darken($popover-bg, 3%); $popover-header-bg: $popover-header-bg !global; // darken($popover-bg, 3%)
  // $popover-header-color: $headings-color; $popover-header-color: $popover-header-color !global; // $headings-color
  // $popover-header-padding-y: .5rem; $popover-header-padding-y: $popover-header-padding-y !global; // .5rem
  // $popover-header-padding-x: .75rem; $popover-header-padding-x: $popover-header-padding-x !global; // .75rem

  // $popover-body-color: $body-color; $popover-body-color: $popover-body-color !global; // $body-color
  // $popover-body-padding-y: $popover-header-padding-y; $popover-body-padding-y: $popover-body-padding-y !global; // $popover-header-padding-y
  // $popover-body-padding-x: $popover-header-padding-x; $popover-body-padding-x: $popover-body-padding-x !global; // $popover-header-padding-x

  // $popover-arrow-width: 1rem; $popover-arrow-width: $popover-arrow-width !global; // 1rem
  // $popover-arrow-height: .5rem; $popover-arrow-height: $popover-arrow-height !global; // .5rem
  // $popover-arrow-color: $popover-bg; $popover-arrow-color: $popover-arrow-color !global; // $popover-bg

  // $popover-arrow-outer-color: fade-in($popover-border-color, .05); $popover-arrow-outer-color: $popover-arrow-outer-color !global; // fade-in($popover-border-color, .05)


  // Toasts

  // $toast-max-width: 350px; $toast-max-width: $toast-max-width !global; // 350px
  // $toast-padding-x: .75rem; $toast-padding-x: $toast-padding-x !global; // .75rem
  // $toast-padding-y: .25rem; $toast-padding-y: $toast-padding-y !global; // .25rem
  // $toast-font-size: .875rem; $toast-font-size: $toast-font-size !global; // .875rem
  // $toast-color: null; $toast-color: $toast-color !global; // null
  // $toast-background-color: rgba($white, .85); $toast-background-color: $toast-background-color !global; // rgba($white, .85)
  // $toast-border-width: 1px; $toast-border-width: $toast-border-width !global; // 1px
  // $toast-border-color: rgba(0, 0, 0, .1); $toast-border-color: $toast-border-color !global; // rgba(0, 0, 0, .1)
  // $toast-border-radius: .25rem; $toast-border-radius: $toast-border-radius !global; // .25rem
  // $toast-box-shadow: 0 .25rem .75rem rgba($black, .1); $toast-box-shadow: $toast-box-shadow !global; // 0 .25rem .75rem rgba($black, .1)

  // $toast-header-color: $gray-600; $toast-header-color: $toast-header-color !global; // $gray-600
  // $toast-header-background-color: rgba($white, .85); $toast-header-background-color: $toast-header-background-color !global; // rgba($white, .85)
  // $toast-header-border-color: rgba(0, 0, 0, .05); $toast-header-border-color: $toast-header-border-color !global; // rgba(0, 0, 0, .05)


  // Badges

  // $badge-font-size: 75%; $badge-font-size: $badge-font-size !global; // 75%
  // $badge-font-weight: $font-weight-bold; $badge-font-weight: $badge-font-weight !global; // $font-weight-bold
  // $badge-padding-y: .25em; $badge-padding-y: $badge-padding-y !global; // .25em
  // $badge-padding-x: .4em; $badge-padding-x: $badge-padding-x !global; // .4em
  // $badge-border-radius: $border-radius; $badge-border-radius: $badge-border-radius !global; // $border-radius

  // $badge-transition: $btn-transition; $badge-transition: $badge-transition !global; // $btn-transition
  // $badge-focus-width: $input-btn-focus-width; $badge-focus-width: $badge-focus-width !global; // $input-btn-focus-width

  // $badge-pill-padding-x: .6em; $badge-pill-padding-x: $badge-pill-padding-x !global; // .6em
  // Use a higher than normal value to ensure completely rounded edges when
  // customizing padding or font-size on labels.
  // $badge-pill-border-radius: 10rem; $badge-pill-border-radius: $badge-pill-border-radius !global; // 10rem


  // Modals

  // Padding applied to the modal body
  // $modal-inner-padding: 1rem; $modal-inner-padding: $modal-inner-padding !global; // 1rem

  // Margin between elements in footer, must be lower than or equal to 2 * $modal-inner-padding
  // $modal-footer-margin-between: .5rem; $modal-footer-margin-between: $modal-footer-margin-between !global; // .5rem
  // $modal-dialog-margin: .5rem; $modal-dialog-margin: $modal-dialog-margin !global; // .5rem
  // $modal-dialog-margin-y-sm-up: 1.75rem; $modal-dialog-margin-y-sm-up: $modal-dialog-margin-y-sm-up !global; // 1.75rem

  // $modal-title-line-height: $line-height-base; $modal-title-line-height: $modal-title-line-height !global; // $line-height-base

  // $modal-content-color: null; $modal-content-color: $modal-content-color !global; // null
  // $modal-content-bg: $white; $modal-content-bg: $modal-content-bg !global; // $white
  // $modal-content-border-color: rgba($black, .2); $modal-content-border-color: $modal-content-border-color !global; // rgba($black, .2)
  // $modal-content-border-width: $border-width; $modal-content-border-width: $modal-content-border-width !global; // $border-width
  // $modal-content-border-radius: $border-radius-lg; $modal-content-border-radius: $modal-content-border-radius !global; // $border-radius-lg
  // $modal-content-inner-border-radius: subtract($modal-content-border-radius, $modal-content-border-width); $modal-content-inner-border-radius: $modal-content-inner-border-radius !global; // subtract($modal-content-border-radius, $modal-content-border-width)
  // $modal-content-box-shadow-xs: 0 .25rem .5rem rgba($black, .5); $modal-content-box-shadow-xs: $modal-content-box-shadow-xs !global; // 0 .25rem .5rem rgba($black, .5)
  // $modal-content-box-shadow-sm-up: 0 .5rem 1rem rgba($black, .5); $modal-content-box-shadow-sm-up: $modal-content-box-shadow-sm-up !global; // 0 .5rem 1rem rgba($black, .5)

  // $modal-backdrop-bg: $black; $modal-backdrop-bg: $modal-backdrop-bg !global; // $black
  // $modal-backdrop-opacity: .5; $modal-backdrop-opacity: $modal-backdrop-opacity !global; // .5
  // $modal-header-border-color: $border-color; $modal-header-border-color: $modal-header-border-color !global; // $border-color
  // $modal-footer-border-color: $modal-header-border-color; $modal-footer-border-color: $modal-footer-border-color !global; // $modal-header-border-color
  // $modal-header-border-width: $modal-content-border-width; $modal-header-border-width: $modal-header-border-width !global; // $modal-content-border-width
  // $modal-footer-border-width: $modal-header-border-width; $modal-footer-border-width: $modal-footer-border-width !global; // $modal-header-border-width
  // $modal-header-padding-y: 1rem; $modal-header-padding-y: $modal-header-padding-y !global; // 1rem
  // $modal-header-padding-x: 1rem; $modal-header-padding-x: $modal-header-padding-x !global; // 1rem
  // $modal-header-padding: $modal-header-padding-y $modal-header-padding-x; $modal-header-padding: $modal-header-padding !global; // $modal-header-padding-y $modal-header-padding-x // Keep this for backwards compatibility

  // $modal-xl: 1140px; $modal-xl: $modal-xl !global; // 1140px
  // $modal-lg: 800px; $modal-lg: $modal-lg !global; // 800px
  // $modal-md: 500px; $modal-md: $modal-md !global; // 500px
  // $modal-sm: 300px; $modal-sm: $modal-sm !global; // 300px

  // $modal-fade-transform: translate(0, -50px); $modal-fade-transform: $modal-fade-transform !global; // translate(0, -50px)
  // $modal-show-transform: none; $modal-show-transform: $modal-show-transform !global; // none
  // $modal-transition: transform .3s ease-out; $modal-transition: $modal-transition !global; // transform .3s ease-out
  // $modal-scale-transform: scale(1.02); $modal-scale-transform: $modal-scale-transform !global; // scale(1.02)


  // Alerts
  //
  // Define alert colors, border radius, and padding.

  // $alert-padding-y: .75rem; $alert-padding-y: $alert-padding-y !global; // .75rem
  // $alert-padding-x: 1.25rem; $alert-padding-x: $alert-padding-x !global; // 1.25rem
  // $alert-margin-bottom: 1rem; $alert-margin-bottom: $alert-margin-bottom !global; // 1rem
  // $alert-border-radius: $border-radius; $alert-border-radius: $alert-border-radius !global; // $border-radius
  // $alert-link-font-weight: $font-weight-bold; $alert-link-font-weight: $alert-link-font-weight !global; // $font-weight-bold
  // $alert-border-width: $border-width; $alert-border-width: $alert-border-width !global; // $border-width

  // $alert-bg-level: -10; $alert-bg-level: $alert-bg-level !global; // -10
  // $alert-border-level: -9; $alert-border-level: $alert-border-level !global; // -9
  // $alert-color-level: 6; $alert-color-level: $alert-color-level !global; // 6


  // Progress bars

  // $progress-height: 1rem; $progress-height: $progress-height !global; // 1rem
  // $progress-font-size: $font-size-base * .75; $progress-font-size: $progress-font-size !global; // $font-size-base * .75
  // $progress-bg: $gray-200; $progress-bg: $progress-bg !global; // $gray-200
  // $progress-border-radius: $border-radius; $progress-border-radius: $progress-border-radius !global; // $border-radius
  // $progress-box-shadow: inset 0 .1rem .1rem rgba($black, .1); $progress-box-shadow: $progress-box-shadow !global; // inset 0 .1rem .1rem rgba($black, .1)
  // $progress-bar-color: $white; $progress-bar-color: $progress-bar-color !global; // $white
  // $progress-bar-bg: theme-color("primary"); $progress-bar-bg: $progress-bar-bg !global; // theme-color("primary")
  // $progress-bar-animation-timing: 1s linear infinite; $progress-bar-animation-timing: $progress-bar-animation-timing !global; // 1s linear infinite
  // $progress-bar-transition: width .6s ease; $progress-bar-transition: $progress-bar-transition !global; // width .6s ease


  // List group

  // $list-group-color: null; $list-group-color: $list-group-color !global; // null
  // $list-group-bg: $white; $list-group-bg: $list-group-bg !global; // $white
  // $list-group-border-color: rgba($black, .125); $list-group-border-color: $list-group-border-color !global; // rgba($black, .125)
  // $list-group-border-width: $border-width; $list-group-border-width: $list-group-border-width !global; // $border-width
  // $list-group-border-radius: $border-radius; $list-group-border-radius: $list-group-border-radius !global; // $border-radius

  // $list-group-item-padding-y: .75rem; $list-group-item-padding-y: $list-group-item-padding-y !global; // .75rem
  // $list-group-item-padding-x: 1.25rem; $list-group-item-padding-x: $list-group-item-padding-x !global; // 1.25rem

  // $list-group-hover-bg: $gray-100; $list-group-hover-bg: $list-group-hover-bg !global; // $gray-100
  // $list-group-active-color: $component-active-color; $list-group-active-color: $list-group-active-color !global; // $component-active-color
  // $list-group-active-bg: $component-active-bg; $list-group-active-bg: $list-group-active-bg !global; // $component-active-bg
  // $list-group-active-border-color: $list-group-active-bg; $list-group-active-border-color: $list-group-active-border-color !global; // $list-group-active-bg

  // $list-group-disabled-color: $gray-600; $list-group-disabled-color: $list-group-disabled-color !global; // $gray-600
  // $list-group-disabled-bg: $list-group-bg; $list-group-disabled-bg: $list-group-disabled-bg !global; // $list-group-bg

  // $list-group-action-color: $gray-700; $list-group-action-color: $list-group-action-color !global; // $gray-700
  // $list-group-action-hover-color: $list-group-action-color; $list-group-action-hover-color: $list-group-action-hover-color !global; // $list-group-action-color

  // $list-group-action-active-color: $body-color; $list-group-action-active-color: $list-group-action-active-color !global; // $body-color
  // $list-group-action-active-bg: $gray-200; $list-group-action-active-bg: $list-group-action-active-bg !global; // $gray-200


  // Image thumbnails

  // $thumbnail-padding: .25rem; $thumbnail-padding: $thumbnail-padding !global; // .25rem
  // $thumbnail-bg: $body-bg; $thumbnail-bg: $thumbnail-bg !global; // $body-bg
  // $thumbnail-border-width: $border-width; $thumbnail-border-width: $thumbnail-border-width !global; // $border-width
  // $thumbnail-border-color: $gray-300; $thumbnail-border-color: $thumbnail-border-color !global; // $gray-300
  // $thumbnail-border-radius: $border-radius; $thumbnail-border-radius: $thumbnail-border-radius !global; // $border-radius
  // $thumbnail-box-shadow: 0 1px 2px rgba($black, .075); $thumbnail-box-shadow: $thumbnail-box-shadow !global; // 0 1px 2px rgba($black, .075)


  // Figures

  // $figure-caption-font-size: 90%; $figure-caption-font-size: $figure-caption-font-size !global; // 90%
  // $figure-caption-color: $gray-600; $figure-caption-color: $figure-caption-color !global; // $gray-600


  // Breadcrumbs

  // $breadcrumb-font-size: null; $breadcrumb-font-size: $breadcrumb-font-size !global; // null
  // $breadcrumb-padding-y: .75rem; $breadcrumb-padding-y: $breadcrumb-padding-y !global; // .75rem
  // $breadcrumb-padding-x: 1rem; $breadcrumb-padding-x: $breadcrumb-padding-x !global; // 1rem
  // $breadcrumb-item-padding: .5rem; $breadcrumb-item-padding: $breadcrumb-item-padding !global; // .5rem

  // $breadcrumb-margin-bottom: 1rem; $breadcrumb-margin-bottom: $breadcrumb-margin-bottom !global; // 1rem

  // $breadcrumb-bg: $gray-200; $breadcrumb-bg: $breadcrumb-bg !global; // $gray-200
  // $breadcrumb-divider-color: $gray-600; $breadcrumb-divider-color: $breadcrumb-divider-color !global; // $gray-600
  // $breadcrumb-active-color: $gray-600; $breadcrumb-active-color: $breadcrumb-active-color !global; // $gray-600
  // $breadcrumb-divider: quote("/"); $breadcrumb-divider: $breadcrumb-divider !global; // quote("/")

  // $breadcrumb-border-radius: $border-radius; $breadcrumb-border-radius: $breadcrumb-border-radius !global; // $border-radius


  // Carousel

  // $carousel-control-color: $white; $carousel-control-color: $carousel-control-color !global; // $white
  // $carousel-control-width: 15%; $carousel-control-width: $carousel-control-width !global; // 15%
  // $carousel-control-opacity: .5; $carousel-control-opacity: $carousel-control-opacity !global; // .5
  // $carousel-control-hover-opacity: .9; $carousel-control-hover-opacity: $carousel-control-hover-opacity !global; // .9
  // $carousel-control-transition: opacity .15s ease; $carousel-control-transition: $carousel-control-transition !global; // opacity .15s ease

  // $carousel-indicator-width: 30px; $carousel-indicator-width: $carousel-indicator-width !global; // 30px
  // $carousel-indicator-height: 3px; $carousel-indicator-height: $carousel-indicator-height !global; // 3px
  // $carousel-indicator-hit-area-height: 10px; $carousel-indicator-hit-area-height: $carousel-indicator-hit-area-height !global; // 10px
  // $carousel-indicator-spacer: 3px; $carousel-indicator-spacer: $carousel-indicator-spacer !global; // 3px
  // $carousel-indicator-active-bg: $white; $carousel-indicator-active-bg: $carousel-indicator-active-bg !global; // $white
  // $carousel-indicator-transition: opacity .6s ease; $carousel-indicator-transition: $carousel-indicator-transition !global; // opacity .6s ease

  // $carousel-caption-width: 70%; $carousel-caption-width: $carousel-caption-width !global; // 70%
  // $carousel-caption-color: $white; $carousel-caption-color: $carousel-caption-color !global; // $white

  // $carousel-control-icon-width: 20px; $carousel-control-icon-width: $carousel-control-icon-width !global; // 20px

  // $carousel-control-prev-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>"); $carousel-control-prev-icon-bg: $carousel-control-prev-icon-bg !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M5.25 0l-4 4 4 4 1.5-1.5L4.25 4l2.5-2.5L5.25 0z'/></svg>")
  // $carousel-control-next-icon-bg: url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>"); $carousel-control-next-icon-bg: $carousel-control-next-icon-bg !global; // url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' width='8' height='8' viewBox='0 0 8 8'><path d='M2.75 0l-1.5 1.5L3.75 4l-2.5 2.5L2.75 8l4-4-4-4z'/></svg>")

  // $carousel-transition-duration: .6s; $carousel-transition-duration: $carousel-transition-duration !global; // .6s
  // $carousel-transition: transform $carousel-transition-duration ease-in-out; $carousel-transition: $carousel-transition !global; // transform $carousel-transition-duration ease-in-out // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)


  // Spinners

  // $spinner-width: 2rem; $spinner-width: $spinner-width !global; // 2rem
  // $spinner-height: $spinner-width; $spinner-height: $spinner-height !global; // $spinner-width
  // $spinner-border-width: .25em; $spinner-border-width: $spinner-border-width !global; // .25em

  // $spinner-width-sm: 1rem; $spinner-width-sm: $spinner-width-sm !global; // 1rem
  // $spinner-height-sm: $spinner-width-sm; $spinner-height-sm: $spinner-height-sm !global; // $spinner-width-sm
  // $spinner-border-width-sm: .2em; $spinner-border-width-sm: $spinner-border-width-sm !global; // .2em


  // Close

  // $close-font-size: $font-size-base * 1.5; $close-font-size: $close-font-size !global; // $font-size-base * 1.5
  // $close-font-weight: $font-weight-bold; $close-font-weight: $close-font-weight !global; // $font-weight-bold
  // $close-color: $black; $close-color: $close-color !global; // $black
  // $close-text-shadow: 0 1px 0 $white; $close-text-shadow: $close-text-shadow !global; // 0 1px 0 $white


  // Code

  // $code-font-size: 87.5%; $code-font-size: $code-font-size !global; // 87.5%
  // $code-color: $pink; $code-color: $code-color !global; // $pink

  // $kbd-padding-y: .2rem; $kbd-padding-y: $kbd-padding-y !global; // .2rem
  // $kbd-padding-x: .4rem; $kbd-padding-x: $kbd-padding-x !global; // .4rem
  // $kbd-font-size: $code-font-size; $kbd-font-size: $kbd-font-size !global; // $code-font-size
  // $kbd-color: $white; $kbd-color: $kbd-color !global; // $white
  // $kbd-bg: $gray-900; $kbd-bg: $kbd-bg !global; // $gray-900

  // $pre-color: $gray-900; $pre-color: $pre-color !global; // $gray-900
  // $pre-scrollable-max-height: 340px; $pre-scrollable-max-height: $pre-scrollable-max-height !global; // 340px


  // Utilities

  // $displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex; $displays: $displays !global; // none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex
  // $overflows: auto, hidden; $overflows: $overflows !global; // auto, hidden
  // $positions: static, relative, absolute, fixed, sticky; $positions: $positions !global; // static, relative, absolute, fixed, sticky
  // $user-selects: all, auto, none; $user-selects: $user-selects !global; // all, auto, none

  // Printing

  // $print-page-size: a3; $print-page-size: $print-page-size !global; // a3
  // $print-body-min-width: map-get($grid-breakpoints, "lg"); $print-body-min-width: $print-body-min-width !global; // map-get($grid-breakpoints, "lg")
}
